<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2>План</h2>
      <div class="d-flex gap-3">
        <UIButton
          class="add-btn"
          text="Создать заказ"
          leftIcon="bi-plus-square"
          @click="createOrder"
        />
      </div>
    </div>
    <div class="mb-3 d-flex">
      <div class="w-50">
        <div v-if="!periodChartData" class="chart-loader text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <BarChart
          v-else
          :title="
            config.targetYearValue
              ? 'Год - ' + config.targetYearValue
              : 'Выберите период'
          "
          :chartData="periodChartData"
          :stepSize="100"
          :select.sync="config.targetYearId"
        >
        </BarChart>
      </div>
      <div class="w-50">
        <div v-if="!yearChartData" class="chart-loader text-center w-50">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <BarChart
          v-else
          :title="
            'Месяц - ' + yearChartData.labels[config.targetMonthId].toString()
          "
          :chartData="yearChartData"
          :select.sync="config.targetMonthId"
        />
      </div>
    </div>
    <Tabs :headers="['Реализовано', 'Запланировано']" @selectTab="selectKind">
      <template v-slot:tab-0>
        <TableSlot v-bind="works" @rowClick="goToWork">
          <template v-slot:default="{ row, index }">
            <td>{{ index }}</td>
            <td>
              {{ lodash.get(row, "BaseWork.Document.title", "Без названия") }}
            </td>
            <td>
              {{ lodash.get(row, "workOrderNumber", "Без номера") }}
            </td>
            <td>
              {{
                new Date(
                  lodash.get(
                    row,
                    "BaseWork.TimeSchedules[0].TimeSchedule.scheduleInterval.start"
                  )
                ).toLocaleDateString()
              }}
            </td>
            <td>
              {{
                new Date(
                  lodash.get(
                    row,
                    "BaseWork.TimeSchedules[0].TimeSchedule.scheduleInterval.end"
                  )
                ).toLocaleDateString()
              }}
            </td>
            <td>
              {{
                lodash.get(row, "BaseWork.statusKind.ru_value", "Без статуса")
              }}
            </td>
          </template>
        </TableSlot>
      </template>
      <template v-slot:tab-1>
        <TableSlot v-bind="works" @rowClick="goToWork" />
      </template>
    </Tabs>
  </div>
</template>
<script>
import TableSlot from "@/components/TableSlot";
import UIButton from "@/components/UI/Button.vue";
import BarChart from "@/components/Chart/Bar";
import Tabs from "@/components/UI/Tabs";

import {
  API_work_list,
  API_work_overview_months,
  API_work_overview_years,
} from "@/services/work";

export default {
  name: "Overview",
  components: { UIButton, BarChart, Tabs, TableSlot },
  data() {
    return {
      config: {
        yearIsMoney: false,
        targetYearId: 0,
        targetYearValue: this.$route.params["year"],
        targetMonthId: parseInt(this.$route.params["month"]) - 1,
        targetMonthValue: parseInt(this.$route.params["month"]),
        targetKind: "actual",
      },
      periodChartData: null,
      yearChartData: null,
      works: {
        columns: [
          {
            name: "№",
            path: "id",
          },
          {
            name: "Наименование",
            path: "BaseWork.Document.title",
          },
          {
            name: "Номер заказа",
            path: "workOrderNumber",
          },
          {
            name: "Дата начала",
            path: "BaseWork.TimeSchedules[0].TimeSchedule.scheduleInterval.start",
          },
          {
            name: "Дата окончания",
            path: "BaseWork.TimeSchedules[0].TimeSchedule.scheduleInterval.end",
          },
          {
            name: "Статус",
            path: "BaseWork.statusKind.ru_value",
          },
        ],
        data: [],
        filters: {
          "BaseWork.Document.title": "",
          workOrderNumber: "",
        },
      },
    };
  },
  async mounted() {
    await this.updateChartWorksInPeriod();
    await this.updateChartWorksInYear();
    await this.updateWorksList();
  },
  watch: {
    "config.yearIsMoney"() {
      this.updateChartWorksInPeriod();
    },
    "config.targetYearId"() {
      this.config.targetYearValue =
        this.periodChartData.labels[this.config.targetYearId].toString();
      this.updateChartWorksInYear();
      this.$route.params["year"] = this.config.targetYearValue;
      this.$router.replace({
        name: "Overview",
        params: {
          year: this.config.targetYearValue,
          month: this.config.targetMonthValue,
        },
      });
    },
    "config.targetMonthId"() {
      this.config.targetMonthValue = this.config.targetMonthId + 1;
      this.updateWorksList();
      this.$router.replace({
        name: "Overview",
        params: {
          year: this.config.targetYearValue,
          month: this.config.targetMonthValue,
        },
      });
    },
    "config.targetKind"() {
      this.updateWorksList();
    },
  },
  methods: {
    selectKind(value) {
      if (value === "Реализовано") {
        this.config.targetKind = "actual";
      } else {
        this.config.targetKind = "request";
      }
    },
    async updateChartWorksInPeriod() {
      await API_work_overview_years(this.config.yearIsMoney).then((res) => {
        this.periodChartData = res;
      });
    },
    async updateChartWorksInYear() {
      await API_work_overview_months(this.config.targetYearValue).then(
        (res) => {
          this.yearChartData = res;
        }
      );
    },
    async updateWorksList() {
      await API_work_list(
        this.config.targetYearValue,
        this.config.targetMonthValue,
        this.config.targetKind
      ).then((res) => {
        this.works.data = res;
      });
    },
    reset() {
      this.config.targetYearId = 0;
      this.config.targetMonthId = 0;
    },
    async createOrder() {
      await this.$router.push("/overview/order/add");
    },
    goToWork: function (item) {
      this.$router.push({
        name: "Order view",
        params: {
          order: item.id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
#overview {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

#order-table-box {
  margin-top: 50px;

  .add-btn {
    display: block;
    margin: 15px 0 15px auto;
  }
}

#tip {
  width: auto;
  margin: auto;
  border: solid 1px #7cb5ec;
  border-radius: $border-radius;
  padding: 10px 0 8px;
  background-color: #7cb5ec33;
  text-align: center;
  cursor: default;
}

#charts-holder {
  $chart-height: 300px;

  & > * {
    max-height: $chart-height;
    width: 50%;
    display: inline-block;
    vertical-align: middle;
  }

  .chart-loader {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: $chart-height;
    background-color: $main-1;
    border: solid 2px $main-3;
  }
}

#chart-metrics {
  margin-bottom: 10px;
  input {
    display: none;
  }
  label {
    display: inline-block;
    padding: 8px 14px 7px;
    background: #273661;
    cursor: pointer;
    font-weight: bold;

    &.active {
      background-color: $highlight;
      color: #000;
    }

    &:first-of-type {
      border-radius: $border-radius 0 0 $border-radius;
    }
    &:last-of-type {
      border-radius: 0 $border-radius $border-radius 0;
    }
  }
}
</style>
