<template>
  <div class="tabs-box">
    <ul class="pb-1">
      <li
        v-for="(header, index) in headers"
        :class="index === current ? 'active border border-1' : ''"
        :key="header"
        @click="
          toggleTab(index);
          $emit('selectTab', header);
        "
      >
        {{ header }}
      </li>
    </ul>
    <div class="tab-panel">
      <template v-for="(header, index) in headers">
        <TabItem :key="header" v-show="index === current">
          <template v-slot:default>
            <slot :name="`tab-${index}`"></slot>
          </template>
        </TabItem>
      </template>
    </div>
  </div>
</template>

<script>
import TabItem from "@/components/UI/TabItem";

export default {
  components: {
    TabItem,
  },
  props: {
    headers: Array,
    setTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      current: this.setTab,
    };
  },
  methods: {
    toggleTab(tabIndex) {
      this.current = tabIndex;
    },
  },
};
</script>

<style lang="scss">
.tabs-box {
  ul {
    padding: 0;
    margin-bottom: 0px;
    list-style: none;
    border-bottom: 1px solid $main-2;

    li {
      position: relative;
      display: inline-block;
      padding: 0.715rem 1.072rem;
      border-radius: $border-radius;
      font-size: 16px;
      cursor: pointer;
      z-index: 1;

      &:hover::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: $border-radius $border-radius 0 0;
        background-color: $main-2;
        opacity: 0.6;
        z-index: -1;
      }

      &.active {
        background-color: $main-2;
        font-weight: bold;
      }
    }
  }
  .tab-panel {
    background-color: $main-2;
  }
}
</style>
