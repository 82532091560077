<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TabItem",
};
</script>

<style scoped></style>
