<template>
  <div class="chart-box">
    <div class="head-panel">
      <h3 v-if="title" v-text="title"></h3>
      <slot name="head-panel"></slot>
    </div>
    <Bar
      class="chart-panel"
      ref="bar"
      :chart-options="chartOptions"
      :chart-data="chartData"
    />
  </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
export default {
  name: "BarChart",
  components: { Bar },
  props: {
    title: String,
    chartData: {
      required: true,
      type: Object,
      default: () => {
        return {
          labels: ["January", "February", "March"],
          datasets: [{ data: [40, 20, 12] }],
        };
      },
    },
    stepSize: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        onClick: (e) => {
          const id = e.chart.scales.x.getValueForPixel(e.x);
          this.$emit("update:select", id);
        },
        scales: {
          y: {
            ticks: {
              stepSize: this.stepSize,
            },
          },
        },
      },
    };
  },
  mounted() {
    this.$refs.bar.updateChart();
  },
};
</script>

<style lang="scss">
.chart-box {
  border: solid 2px $main-1;

  .head-panel {
    background-color: $main-1;
    height: 2.5rem;
    width: 100%;
    text-align: center;
  }

  .chart-panel {
    height: 16rem;
  }
}
</style>
